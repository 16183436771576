import React from 'react';
import { mq, theme } from "../../theme";
import Button, { EButtonType } from "../Button";
import ArrowRightIcon from "../../images/icons/arrow-right.svg";
import ArrowLeftIcon from "../../images/icons/arrow-left.svg";
import ChevronDownIcon from "../../images/icons/chevron-down.svg";
import MenuIcon from "../../images/icons/menu.svg";
import PhoneIcon from "../../images/icons/phone-icon.svg";
import SmartSalesLogo from "../../images/fotos/smartsales-logo.jpeg";
import VodafoneZiggoWhite from "../../images/fotos/vodafoneziggo-white.png";
import { useDetectClickOutside } from 'react-detect-click-outside';
import { CSSTransition } from 'react-transition-group';
import './dropdown.css';
import { faq } from '../../lib/faq';
import { Link } from 'gatsby';


function Navbar(props) {
  return (
    <header
      css={mq({
        paddingLeft: ['0.5rem', null, '1rem'],
        paddingRight: ['0.5rem', null, '1rem'],
      })}
    >
      <nav className="navbar">
        <div css={{ display: 'flex', flex: 1 }}>
          <Link
            to="/"
          >
            <img 
              src={SmartSalesLogo} 
              css={mq({ 
                maxHeight: ['40px', null, '65px'], 
                marginTop: [16, null, -7],
              })} 
            />
          </Link>
        </div>
        <ul className="navbar-nav">{props.children}</ul>
      </nav>
    </header>
  );
}

function NavItem({ icon, text, component, children, ...rest }: any) {
  const [open, setOpen] = React.useState(false);
  const dropdownRef = useDetectClickOutside({ 
    onTriggered: (e) => {
      if(!e || !e.path) {
        return;
      }
      const foundIt = e.path.find((item) => item === dropdownRef.current)
      if(!foundIt) {
        setOpen(false)
      }
    }
  });

  return (
    <li 
      className="nav-item"
      ref={dropdownRef}
      css={mq({
        position: ['static', 'static', 'relative'],
        display: 'flex',
        alignItems: 'center',
      })}
      {...rest}
    >
      <a href="#" onClick={() => setOpen(!open)}>
        { icon && <span className="icon-button" >{icon}</span> }
        { text && <span>{text}</span>}      
        { component }
      </a>

      {open && children}
    </li>
  );
}

const DropdownMenu = () => {
  const [activeMenu, setActiveMenu] = React.useState('main');
  const [menuHeight, setMenuHeight] = React.useState(null);
  const dropdownRef = React.useRef(null);

  React.useEffect(() => {
    setMenuHeight(dropdownRef.current?.lastChild.offsetHeight)
  }, [])

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropdownItem({ goToMenu, leftIcon, children, rightIcon, className, ...rest }: any) {
    return (
      <span
        css={{
          cursor: 'pointer'
        }}
        className={"menu-item" + ' ' + className}
        onClick={(e) => {
          goToMenu && setActiveMenu(goToMenu)
        }} 
        {...rest}
      >
        {leftIcon && <span className="icon-button">{leftIcon}</span>}
        {children}
        {rightIcon && <span className="icon-right">{rightIcon}</span>}
      </span>
    );
  }

  return  <div 
    className="dropdown" 
    css={mq({ 
      height: menuHeight ? menuHeight : 'auto',
      right: [7 , null, 0],
    })} 
    ref={dropdownRef} 
  >

    <CSSTransition
      in={activeMenu === 'main'}
      timeout={500}
      classNames="menu-primary"
      unmountOnExit
      onEnter={calcHeight}>
      <div className="menu">
        
        <Link to={`/contact`}>
          <DropdownItem
            css={{ ':hover': { background: 'white' } }}
            leftIcon={<PhoneIcon css={{ fill: '#fff' }} />}
          >
            <Button type={EButtonType.BLACKONWHITE}>
              Neem contact met ons op
            </Button>
          </DropdownItem>
        </Link>


       <DropdownItem
          rightIcon={<ArrowRightIcon />}
          goToMenu="faq"
        >
          Veelgestelde vragen
        </DropdownItem>

        <Link to={`/terms`}>
          <DropdownItem>
            Algemene Voorwaarden
          </DropdownItem>
        </Link>
      
         
        <Link to={`/privacy`}>
          <DropdownItem>
            Privacy
          </DropdownItem>
        </Link>

        <Link to={`/feedback`}>
          <DropdownItem>
            feedback
          </DropdownItem>
        </Link>

      </div>
    </CSSTransition>

    <CSSTransition
      in={activeMenu === 'faq'}
      timeout={500}
      classNames="menu-secondary"
      unmountOnExit
      onEnter={calcHeight}>
      <div className="menu">
        <DropdownItem goToMenu="main" leftIcon={<ArrowLeftIcon />}>
          <h2>Vorige</h2>
        </DropdownItem>
        {
          faq.filter(faqItem => faqItem.homepage === 1).map((faqItem) => {
            return (
              <Link to={`/faq/${faqItem.slug}`}>
                <DropdownItem
                  // rightIcon={<ArrowRightIcon />}
                >
                  {faqItem.question}
                </DropdownItem>
              </Link>
            );
          })
        }
        <Link to={`/faq/veelgesteldevragen`}>
          <DropdownItem css={{ ':hover': { background: 'white' } }}>
            <Button css={{ margin: '0 auto' }} type={EButtonType.GREEN} callToAction>
              Bekijk alle vragen
            </Button>
          </DropdownItem>
        </Link>
      </div>
    </CSSTransition>

    <CSSTransition
      in={activeMenu === 'animals'}
      timeout={500}
      classNames="menu-secondary"
      unmountOnExit
      onEnter={calcHeight}>
      <div className="menu">
        <DropdownItem goToMenu="main" leftIcon={<ArrowRightIcon />}>
          <h2>Animals</h2>
        </DropdownItem>
        <DropdownItem leftIcon="🦘">Kangaroo</DropdownItem>
        <DropdownItem leftIcon="🐸">Frog</DropdownItem>
        <DropdownItem leftIcon="🦋">Horse?</DropdownItem>
        <DropdownItem leftIcon="🦔">Hedgehog</DropdownItem>
      </div>
    </CSSTransition>
  </div>
}

const Header = () => {
  return (
    <Navbar>
      <NavItem 
        css={mq({
          display: ['none', null, 'inherit'],
        })}
        component={
          <span>
            Informatie <ChevronDownIcon css={{ marginLeft: 4 }} />
          </span>
        }
      >
        <DropdownMenu></DropdownMenu>
      </NavItem>
      {/*<NavItem
        component={(
          <Button onClick={() => alert('klik')} type={EButtonType.GREEN} callToAction>
            Alle Pakketten
          </Button>
        )}
      />*/}
      <NavItem 
        css={mq({
          display: ['inherit', null, 'none'],
        })}
        component={<MenuIcon 
          css={{
            height: 24,
            marginLeft: 6,
          }}
        />}
      >
        <DropdownMenu></DropdownMenu>
      </NavItem>
    </Navbar>
  );

  return (
    <header className="header" css={{ 
      backgroundColor:theme.colors.white,
      margin: '20px 0px 10px 0px',
    }}>
      <nav css={{display:'flex', margin:0, width:'100vw', marginTop:10, flexDirection:'row', justifyContent:'center' }}>
        <div css={{width:115, backgroundImage: `url('${verlengLogo}')`, backgroundSize:'contain'}} onClick={() => window.location.href="/"}></div>
        <div css={{width:20}}>+</div>
        <div css={{width:120, backgroundImage: `url('${VodafoneZiggo}')`, backgroundSize:'contain'}} onClick={() => window.location.href="/"}></div>
        <div css={{width:200}}>
          Informatie
        </div>
        <div css={{width:200}}>Contact</div>
        <Button css={mq({
          fontSize:14,
          height:30,
          fontWeight:600,
          })} onClick={() => alert('klik')} type={EButtonType.GREEN} callToAction>
            Alle Pakketten
        </Button>
      </nav>
    </header>
  );
}

export default Header;
